@import "../../scssPartials/sharedStyles";

.meet-the-founder-wrapper {
  display: flex;
  justify-content: space-between;
}

.meet-the-founder-title {
  @include font(2.25rem, bold, #626880, 3rem);
}

.meet-the-founder-profile-image {
  margin: 2.5rem 0 1rem 0;
}

.meet-the-founder-name {
  @include font(1.5rem, bold, #626880, 2rem);
}

.meet-the-founder-designation {
  @include font(1.1875rem, normal, #626880, 1.625rem);
}

.meet-the-founder-description {
  @include font(1.1875rem, normal, #626880, 1.625rem);
  margin: 1.5rem 0 2.5rem 0;
}

@media only screen and (max-width: 720px) {
  .meet-the-founder-title {
    font-size: 1.5rem;
  }

  .home-meet-the-founder-container {
    display: block;
  }

  .meet-the-founder-wrapper {
    display: flex;
    justify-content: center !important;
  }
}
