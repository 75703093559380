@import "../../scssPartials/sharedStyles";
@mixin card-size($height, $width) {
  height: $height;
  width: $width;
}

.why-hh-card {
  @include display-align(column, space-around, flex-start);
  @include card-size(25.625rem, 14.25rem);
  box-shadow: 0rem 0rem 1.25rem rgb(77 108 217 / 16%);
  border-radius: 1rem;
  background: #4d6cd9;
  padding: 3.75rem 2.1875rem;
}

.why-hh-card-content {
  @include display-align(column, space-evenly, center);
}

.why-hh-card-icon-container {
  @include card-size(5rem, 5rem);
  @include display-align(column, center, center);
}

.why-hh-title-container {
  @include display-align(column, space-between, flex-start);
  height: 3.3125rem;
}

.why-hh-card-title {
  @include font(1.875rem, bold, #ffffff, 2.5rem);
  text-align: left;
}

.why-hh-card-desc {
  @include font(1rem, normal, #dfe5ff, 1.375rem);
  text-align: left;
}
