@import "../../scssPartials/sharedStyles";
.driving-impact-div {
  @include display-align(row, center, center);
}

@media only screen and (max-width: 720px) {
  .driving-impact-div {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
  }

  .driving-impact-div > div {
    margin: 1rem 0;
  }
}
