.site-metrics-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 65.5rem;
}

@media only screen and (max-width: 720px) {
  .site-metrics-div {
    width: 20rem !important;
  }
  .site-metrics-card-container > div {
    margin-top: 3rem;
  }
}
