@import "../../scssPartials/_sharedStyles.scss";

.about-us-container {
  background: #ffffff;
  height: 100%;
  overflow: hidden;
}
.about-us-wrapper {
  display: flex;
  padding-bottom: 8.75rem;
  flex-direction: column;
}

.about-us-hero-image {
  position: relative;
  height: 56.25rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-us-glass-card {
  z-index: 1;
  position: absolute;
  top: 40.125rem;
  left: 12rem;
  background: radial-gradient(
      33.16% 55.85% at 93.58% 8.77%,
      rgba(255, 255, 255, 0.36) 0%,
      rgba(255, 255, 255, 0) 100%
    )
    rgba(77, 108, 217, 0.8);
  box-shadow: 0px 1.25rem 6.25rem rgba(92, 120, 220, 0.3);
  backdrop-filter: blur(1rem);
  border-radius: 1.125rem;
  @include font(1.375rem, 700, #ffffff, 1.875rem);
  padding: 1.375rem 3.75rem 2.4375rem 3.75rem;
}

.about-us-glass-card-text {
  margin-top: 2rem;
  max-width: 28.5rem;
}

.about-us-glass-card-image {
  height: 3rem;
}

.about-us-who-we-are-container {
  background: #f1f7ff;
  height: 48.75rem;
  position: relative;
  display: block;
}

.about-us-who-we-are {
  position: absolute;
  top: 12.75rem;
  left: 42rem;
}

.about-us-who-we-are-card {
  z-index: 1;
  position: absolute;
  top: 28.75rem;
  left: 21rem;
  background: #ffffff;
  box-shadow: 0px 24px 80px rgba(0, 52, 175, 0.32);
  border-radius: 16px;
  padding: 3rem 3.375rem 2.5625rem 3.375rem;
}

.about-us-who-we-are-card-text {
  @include font(1rem, normal, #626880, 1.375rem);
  margin-top: 2rem;
  max-width: 24rem;
}

.about-us-our-commitment-stats {
  height: 98rem;
}

.about-us-our-commitment-title {
  padding: 10.1875rem 0 2.5rem 0;
}

.about-us-our-commitment-backgroung-image {
  @include display-align(column, space-between, center);
  background-size: cover;
  height: 34.1875rem;
  position: relative;
  background-repeat: no-repeat;
}

.about-us-stats {
  margin: 3.75rem 18.5625rem;
}

.about-us-banner-image {
  display: block;
  margin: 8.75rem auto 0 auto;
}

.about-us-what-makes-us-different {
  @include display-align(row, null, center);
  padding: 3.75rem 3.75rem 0 3.75rem;
}
.about-us-what-makes-us-different-title {
  @include display-align(row, center, center);
}

.about-us-why-hiringhood {
  margin-top: 8.75rem;
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.5rem;
  text-align: center;
  color: #626880;
}

.about-us-why-hiringhood-image {
  display: block;
  margin: 0 auto;
}

.about-us-meet-the-team-text {
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.5rem;
  margin: 4.43rem 0 2.5rem 0;
  text-align: center;
}

.about-us-meet-the-team-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(21.375rem, 1fr));
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  margin: 0 9.125rem;
}

.about-us-profile-card {
  margin-left: 1.5rem;
}

.about-us-values-at-our-core {
  @include display-align(row, center, center);
}

.about-us-lets-chat {
  margin: 4.75rem 3rem 7.125rem 3rem;
}

.about-us-blogs-container {
  margin: 8.75rem 3rem 0 3rem;
}

.about-us-subscribe-container {
  display: flex;
  left: 0;
  right: 0;
  margin: auto;
}

@media only screen and (max-width: 720px) {
  .about-us-hero-image {
    height: 40rem;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .about-us-glass-card {
    position: absolute;
    top: 0;
  }

  .about-us-glass-card {
    z-index: 1;
    position: absolute;
    top: 24rem;
    left: 0;
    line-height: 1.8rem;
    margin: 0 1rem;
    color: #ffffff;
    padding: 1.375rem 3.75rem 2.4375rem 3.75rem;
  }

  .about-us-who-we-are-card {
    left: 0;
    margin: 0 1rem;
    top: 20rem;
  }

  .about-us-what-makes-us-different {
    padding: 0;
    height: 30rem;
  }
  .about-us-what-makes-us-different-title {
    margin-top: 30rem;
    padding: 5.75rem 3.75rem 0 3.75rem;
  }

  .about-us-meet-the-team-container {
    display: flex !important;
    margin: auto;
    flex-direction: column;
  }

  .about-us-lets-chat {
    margin: 2rem 0.5rem;
  }
}
