@import "../../scssPartials/sharedStyles";

@mixin card-size($height, $width) {
  height: $height;
  width: $width;
}

.driving-impact-card {
  @include display-align(column, space-evenly, center);
  @include card-size(30.0625rem, 25.3125rem);
  border-radius: 1rem;
  background: #ffffff;
  box-shadow: 0rem 0rem 1.25rem rgba(77, 108, 217, 0.16);
  margin: 0 0.75rem;
}

.driving-impact-card-content {
  @include display-align(column, space-evenly, center);
}

.driving-impact-card-icon-container {
  @include card-size(5rem, 5rem);
  @include display-align(column, center, center);
}

.driving-impact-card-title {
  @include font(1.375rem, bold, #626880, 1.875rem);
  text-align: center;
}

.driving-impact-card-desc-container {
  @include card-size(5.75rem, 13.875rem);
  @include display-align(column, center, center);
}

.driving-impact-card-desc {
  @include font(1rem, normal, #626880, 1.375rem);
  text-align: center;
  width: 10.5rem;
  margin: 0 1.375rem;
}

.driving-impact-card-stat {
  @include font(1.875rem, bold, #626880, 2.5rem);
}
