@import "../../scssPartials/sharedStyles";
.customer-says-container {
  @include display-align(column, center, center);
  @include size(14.375rem, 23.75rem);
  background: #ffffff;
  box-shadow: 0rem 0rem 1.25rem rgba(77, 108, 217, 0.16);
  border-radius: 2rem 2rem 2rem 0rem;
  margin: 1rem 0rem;
  padding-left: 0.5rem;
}

.customer-says-text {
  position: absolute;
  width: 19.6875rem;
}

.customer-says-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 1rem;
}

.customer-says-profile {
  @include display-align(row, space-around, center);
  width: 24.75rem;
}

.customer-says-title-container {
  @include display-align(column, null, flex-start);
  @include size(2.5rem, 20rem);
}
.customer-says-title {
  @include font(1rem, bold, #f1f7ff, 1.375rem);
}
.customer-says-desc {
  @include font(0.8125rem, normal, #f1f7ff, 1.125rem);
  padding-bottom: 1rem;
}
