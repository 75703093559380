@import "../../scssPartials/sharedStyles";

.our-partners-title {
  text-align: center;
  margin-bottom: 2rem;
}

.our-partners-category {
  @include font(1.1875rem, bold, #30374c, 1.625rem);
  margin-top: 1rem;
}

.our-partners-slide {
  display: grid;
  grid-template-columns: repeat(3, minmax(15rem, auto));
  padding: 3rem 5rem 0 5rem;
  height: 8rem;
  margin-bottom: 3rem;
}

.our-partners-partner {
  background: #ffffff;
  box-shadow: 0px 0px 1.25rem rgba(77, 108, 217, 0.16);
  border-radius: 1rem;
  height: 5rem;
  position: relative;
  cursor: pointer;
  width: 15rem;
  max-width: 15rem;
}

.our-partners-partner-logo {
  object-fit: contain;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 80%;
  max-height: 80%;
}

@media only screen and (max-width: 720px) {
  .our-partners-partners {
    display: none;
  }
  .our-partners-partner-mb {
    // display: none;
  }
}
