.js-profile-contest-details {
  padding: 0.5rem 1.5rem;
  border: 1px solid #d6d6d6;
  margin: 1.5rem 1rem;
  display: flex;
  gap: 2.5rem;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
}

.js-profile-contest-details-badge {
  color: #000000;
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
}

.js-profile-contest-details-type {
  font-size: 1rem;
  margin: 0.25rem 0 1rem 0;
}

.js-profile-contest-details-section-1-text {
  font-size: 1rem;
  margin: 0;
  color: #000000;
}

.js-profile-contest-details-section-1-text-value {
  color: rgba(0, 0, 0, 0.6);
  margin-left: 0.5rem;
}

.js-profile-contest-details-section-2 {
  min-width: 20rem;
}

.js-profile-contest-details-contest-name {
  margin: 0;
  font-size: 1.5rem;
  color: #000000;
}

.js-profile-contest-details-icons-container {
  width: auto;
  display: flex;
  justify-content: space-between;
}

.js-profile-contest-details-icon-container {
  display: flex;
  font-size: 1rem;
  gap: 0.5rem;
  align-items: center;
  margin-top: 1rem;
}

.js-profile-contest-details-icon-text {
  margin: 0;
}

.js-profile-js-flow * .form-card-holder {
  background-color: #ffffff;
}

.js-profile-js-flow * .save-draft-button {
  margin-left: 2.5rem !important;
}
