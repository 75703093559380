@import "../../scssPartials/sharedStyles";

.what-customer-says-container {
  @include display-align(column, null, center);
  @include size(36.875rem, 90rem);
}

.what-customer-says-cart-wheel {
  @include display-align(row, space-between, flex-start);
  @include size(18.375rem, 78.375rem);
  position: relative;
  top: -3.1875rem;
}

.what-customer-says-slide {
  @include display-align(row, flex-start, null);
  height: 21.875rem;
}
