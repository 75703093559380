/* @import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,700;1,400&family=Roboto&display=swap"); */
/* @import url("./themes/theme.css"); */
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

:root {
  /* Text */
  --primary-text-color: #626880;
  --secondary-text-color: #4d6cd9;
  --disabled-text-color: #a4a4a4;

  /* Buttons */
  --primary-button-background: #4d6cd9;
  --primary-button-hover-background: #9ba9e8;
  --primary-button-active-background: #1749c7;
  --primary-button-disabled-background: #e5e5e5;
  --primary-button-text-color: #fbfbfb;
  --primary-button-disabled-text-color: #a4a4a4;
  --secondary-button-background: #38f4a4;
  --secondary-button-text-color: #4d6cd9;
  --outlined-button-background: #ffffff;
  --outlined-button-text-color: #4d6cd9;
  --outlined-button-active-background: #e8eafa;
  --outlined-button-border-color: #4d6cd9;
  --textonly-button-text-color: #4d6cd9;
  --textonly-button-text-hover-color: #9ba9e8;
  --textonly-button-text-active-color: #1749c7;
  --textonly-button-text-disabled-color: #a4a4a4;
  --option-button-selected-background: #dfe5ff;
  --option-button-unselected-background: #ffffff;
  --option-button-selected-text-color: #4d6cd9;
  --option-button-unselected-text-color: #626880;
  --option-button-selected-border-color: #4d6cd9;
  --option-button-unselected-border-color: #aab0cb;

  /* Radiobutton */
  --primary-radio-button-color: #ced4f0;
  --secondary-radio-button-color: #4d6cd9;

  /* Text input*/
  --text-input-default-color: #aab0cb;
  --text-input-active-color: #626880;
  --text-input-disabled-color: #a4a4a4;
  --text-input-filled-color: #626880;
  --text-input-default-border: #aab0cb;
  --text-input-active-border: #4d6cd9;
  --text-input-disabled-border: #a4a4a4;

  --error-message-color: #ff3b2d;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nunito", sans-serif;
  color: var(--primary-text-color);
  background: #e5e5e5;
  overflow-x: hidden;
  overflow-y: scroll;
}

.wrapper {
  max-width: 85.375rem;
  margin: 0 auto;
}

.main {
  max-width: 100%;
  width: 100%;
  background: #f6f6f6;
  min-height: 50rem;
}

/* Remove default margins */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

/* ::-webkit-scrollbar {
    width: 0.25rem;
  }
  
  ::-webkit-scrollbar-track {
    margin-bottom: 18rem;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #aab0cb;
    border-radius: 0.5rem;
  } */

.loading {
  height: 30rem;
  margin: 15rem 50% 15rem 50%;
}

.MuiTooltip-tooltip {
  font-weight: bold !important;
}

.MuiSlider-thumb:last-child {
  margin-left: -1rem;
}

a {
  text-decoration: none;
}

.MuiMenu-paper .MuiList-root {
  display: table-caption !important;
}

fieldset {
  border: none;
}

.carousel .control-dots .dot {
  height: 0.75rem !important;
  width: 0.75rem !important;
  background: #e8eafa !important;
  border: 0.0625rem solid black !important;
}

.MuiList-root {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.MuiDialog-paper {
  width: -webkit-fill-available !important;
  width: -moz-available !important;
}

.MuiTableRow-root {
  height: auto !important;
}

.MuiTableCell-body {
  word-break: break-word;
}

.mandatory-text {
  font-size: 12.5px;
  color: var(--light-title);
}

.add-team-grid {
  margin-bottom: 16px !important;
}

.add-team-icon {
  margin-right: 8px;
}

.step-clickable .MuiStepLabel-iconContainer {
  cursor: pointer;
}
