.info-card-grid {
  height: 41.5rem;
  width: 58.875rem;
  background: #4d6cd9;
}

@media only screen and (max-width: 720px) {
  .info-card-grid {
    height: 100%;
  }
}
